import {
    findDifferences,
    transformDotNotationObject,
} from 'components/businessVerification/helpers/validate';
import { BusinessVerificationStage, DocumentRejection } from './BusinessVerificationV3Models';
import * as Yup from 'yup';
import { FormikErrors, FormikValues } from 'formik';
import { processedErrors } from 'components/businessVerification/helpers/ExitContinueButtons';
import { BeneficialPersonalOwner, FormValues, OwnerTreeStructure } from './schema';

type ValidationSchemas = Partial<{
    [key in BusinessVerificationStage]: any;
}>;

const registrationInformationSchema = Yup.object({
    registrationInformation: Yup.object({
        entityName: Yup.string()
            .nullable()
            .required('Please enter a name')
            .max(200, 'Maximum 200 characters'),
        entityTypesID: Yup.number().nullable().required('Please select an option'),
        entityTypesOther: Yup.string().max(200, 'Maximum 200 characters').nullable(),
        registrationDate: Yup.string().nullable().required('Please enter a date'),
        registrationNumber: Yup.string()
            .nullable()
            .required('Please enter a number')
            .max(200, 'Maximum 200 characters'),
        taxNumber: Yup.string()
            .nullable()
            .max(100, 'Maximum 100 characters')
            .when('addressCountry', {
                is: 'USA',
                then: (schema) => schema.required('Please enter a number'),
            }),
        doingBusinessAs: Yup.string()
            .nullable()
            .required("Please list all your business's names")
            .max(500, 'Maximum 500 characters'),
        addressStreet: Yup.string()
            .nullable()
            .required("Please enter your business's address")
            .max(200, 'Maximum 200 characters'),
        addressNumber: Yup.string()
            .nullable()
            .required("Please enter your business's address")
            .max(200, 'Maximum 200 characters'),
        addressPostCode: Yup.string()
            .nullable()
            .required("Please enter your business's postal code")
            .max(20, 'Maximum 20 characters'),
        addressCity: Yup.string()
            .nullable()
            .required("Please enter your business's city")
            .max(200, 'Maximum 200 characters'),
        addressState: Yup.string()
            .nullable()
            .required("Please enter your business's state")
            .max(100, 'Maximum 100 characters'),
        addressCountry: Yup.string()
            .nullable()
            .required('Please select an option')
            .max(10, 'Maximum 10 characters'),
        operatingAddressStreet: Yup.string()
            .nullable()
            .optional()
            .max(200, 'Maximum 200 characters'),
        operatingAddressNumber: Yup.string()
            .nullable()
            .optional()
            .max(200, 'Maximum 200 characters'),
        operatingAddressPostCode: Yup.string()
            .nullable()
            .optional()
            .max(20, 'Maximum 20 characters'),
        operatingAddressCity: Yup.string().nullable().optional().max(200, 'Maximum 200 characters'),
        operatingAddressState: Yup.string()
            .nullable()
            .optional()
            .max(100, 'Maximum 100 characters'),
        operatingAddressCountry: Yup.string()
            .nullable()
            .optional()
            .max(10, 'Maximum 10 characters'),
    }).test(
        'operatingPartialProvided',
        'Must Provide full operating address if started',
        function (this) {
            const {
                operatingAddressStreet,
                operatingAddressNumber,
                operatingAddressPostCode,
                operatingAddressCity,
                operatingAddressState,
                operatingAddressCountry,
            } = this.parent.registrationInformation as FormValues['registrationInformation'];

            const operationsAddress: Record<string, string | null> = {
                operatingAddressStreet,
                operatingAddressNumber,
                operatingAddressPostCode,
                operatingAddressCity,
                operatingAddressState,
                operatingAddressCountry,
            };

            if (
                Object.values(operationsAddress).some((val) => !!val) &&
                !Object.values(operationsAddress).every((val) => !!val)
            ) {
                // If any operating address value is given require the rest
                const firstEmptyValue = Object.keys(operationsAddress).find(
                    (opt) => !operationsAddress[opt]
                );
                return this.createError({
                    path: `${this.path}.${firstEmptyValue}`,
                    message: 'Please provide the full address if providing operating address.',
                });
            }

            return true;
        }
    ),
});

const operationsInformationSchema = Yup.object({
    operationsInformation: Yup.object({
        webAddress: Yup.string().nullable().max(200, 'Maximum 200 characters'),
        phoneNumber: Yup.string().nullable().max(100, 'Maximum 100 characters'),
        supportEmail: Yup.string().nullable().max(200, 'Maximum 200 characters'),
        bPubliclyListed: Yup.string().nullable().required('Please select an option'),
        ticker: Yup.string()
            .when('bPubliclyListed', {
                is: (bPubliclyListed: string) => bPubliclyListed === 'Yes',
                then: Yup.string()
                    .nullable()
                    .required("Please provide your business's ticker")
                    .max(500, 'Maximum 500 characters'),
            })
            .nullable(),
        exchanges: Yup.string()
            .when('bPubliclyListed', {
                is: (bPubliclyListed: string) => bPubliclyListed === 'Yes',
                then: Yup.string()
                    .nullable()
                    .required('Please provide the exchange(s) your business is listed on')
                    .max(500, 'Maximum 500 characters'),
            })
            .nullable(),
        operationRegionIds: Yup.array(Yup.number())
            .nullable()
            .min(1, 'Please select one or more options')
            .required('Please select one or more options'),
        bLicenseRequired: Yup.string().nullable().required('Please select an option'),
        additionalLicensingInfo: Yup.string().nullable().max(500, 'Maximum 500 characters'),
        primaryRegulator: Yup.string().nullable().max(500, 'Maximum 500 characters'),
        licenseNumber: Yup.string().nullable().max(100, 'Maximum 100 characters'),
        businessActivityIds: Yup.array(Yup.number())
            .nullable()
            .min(1, 'Please select one or more options')
            .required('Please select one or more options'),
        web3ChainsAndAddresses: Yup.string().nullable().max(500, 'Maximum 500 characters'),
        sourceOfFundsIds: Yup.array(Yup.number())
            .nullable()
            .min(1, 'Please select one or more options')
            .required('Please select one or more options'),
        sourceOfFundsOther: Yup.string().nullable().max(200, 'Maximum 200 characters'),
        activeBanks: Yup.string()
            .nullable()
            .required('Please name your current banking provider')
            .max(500, 'Maximum 500 characters'),
        yearlyTransactionsId: Yup.number().nullable().required('Please select an option'),
        monthlyUsdValueId: Yup.number().nullable().required('Please select an option'),
    }),
});
export const beneficialOwnersStructureSchema: Yup.AnySchema = Yup.object({
    ownersGuid: Yup.string(),
    bIsBusiness: Yup.boolean(),
    percentageOwned: Yup.number()
        .min(0, 'Percentage must be more than 0')
        .max(100, 'Percentage must be less than 100')
        .required('Please provide a percentage of shares owned')
        .typeError('Please provide a percentage of shares owned')
        .nullable(),
    bControllingParty: Yup.boolean()
        .nullable()
        .when('bIsBusiness', {
            is: false,
            then: (schema) =>
                schema.required('Please state whether this party is a controlling party'),
        }),
    positionAtCompany: Yup.string()
        .nullable()
        .when('bIsBusiness', {
            is: false,
            then: (schema) =>
                schema
                    .required('Please provide a position at the company')
                    .max(100, 'Maximum 100 characters'),
        }),
    children: Yup.array(
        // as any because typescript complains otherwise. Even with typeof beneficialOwnersStructure
        Yup.lazy(() => beneficialOwnersStructureSchema.default(undefined)) as any
    ).nullable(),
})
// .test(
//     'hasAControllingParty',
//     'Must have at least one controlling party',
//     function (this, item: any) {
//         /* Checking the isNewNode field so we don't block the validation when adidng
//     a new item. Yup seems to validate from top down so it means if the parent has
//     an error then it will show this error over showing the nested objects errors.
//     In this case it would say the owner needs a controlling party but wouldn't enforce
//     the childrens validation of say the percentage needs to be below 100  */
//         const value = item as OwnerTreeStructure;
//         if (
//             !!value.bIsBusiness &&
//             !value.isNewNode &&
//             value.isMainNode &&
//             !value.children?.some((o) => o.isNewNode)
//         )
//             return (
//                 !!value.bIsBusiness &&
//                 ((value.children as OwnerTreeStructure[] | null) ?? []).some(
//                     (owner) => owner.bControllingParty
//                 )
//             );
//         else return true;
//     }
// );
const individualBeneficialOwnersSchema = Yup.object({
    firstName: Yup.string()
        .max(100, 'Maximum 100 characters')
        .required('Please provide a first name'),
    lastName: Yup.string()
        .max(100, 'Maximum 100 characters')
        .required('Please provide a last name'),
    title: Yup.string().max(100, 'Maximum 100 characters').required('Please provide a title'),
    dateOfBirth: Yup.string().required('Please provide a date of birth').nullable(),
    nationality: Yup.string().required('Please provide a nationality'),
    email: Yup.string()
        .max(200, 'Maximum 200 characters')
        .required('Please provide an email address')
        .test(
            'isRepeatedEmail',
            'Beneficial owners can not share the same email address',
            (value, context) => {
                const arrayParent = (context as any).from?.[1]?.value?.individualBeneficialOwners;
                const isEmailRepeated =
                    arrayParent.filter(
                        (beneficialOwner: BeneficialPersonalOwner) =>
                            beneficialOwner.email === value
                    ).length > 1;
                return !isEmailRepeated;
            }
        ),
    phoneNumber: Yup.string()
        .max(100, 'Maximum 100 characters')
        .required('Please provide a phone number'),
    country: Yup.string().required('Please provide a country'),
    ssn: Yup.string()
        .max(100, 'Maximum 100 characters')
        .required('Please provide a tax ID number/SSN'),
    stateProvince: Yup.string()
        .max(100, 'Maximum 100 characters')
        .required('Please provide a state/province'),
    city: Yup.string().max(200, 'Maximum 200 characters').required('Please provide a city'),
    addressLine1: Yup.string()
        .max(200, 'Maximum 200 characters')
        .required('Please provide a street address'),
    addressLine2: Yup.string().max(200, 'Maximum 200 characters'),
    postcode: Yup.string()
        .max(20, 'Maximum 20 characters')
        .required('Please provide a postal/zip code'),
    proofOfAddressFilename: Yup.string()
        .max(200, 'Maximum 200 characters')
        .required('Please provide a proof of address')
        .nullable(),
});
const businessBeneficialOwnerSchema = Yup.object({
    entityName: Yup.string()
        .max(200, 'Maximum 200 characters')
        .nullable()
        .required('Please enter a name'),
    entityTypesID: Yup.number().nullable().required('Please select an option'),
    entityTypesOther: Yup.string().max(200, 'Maximum 200 characters').nullable(),
    registrationDate: Yup.string().nullable().required('Please enter a date'),
    registrationNumber: Yup.string()
        .max(100, 'Maximum 100 characters')
        .nullable()
        .required('Please enter a number'),
    taxNumber: Yup.string()
        .max(100, 'Maximum 100 characters')
        .nullable()
        .required('Please enter a number'),
    doingBusinessAs: Yup.string()
        .max(500, 'Maximum 500 characters')
        .nullable()
        .required("Please list all your business's names"),
    addressStreet: Yup.string()
        .max(200, 'Maximum 200 characters')
        .nullable()
        .required("Please enter your business's address"),
    addressNumber: Yup.string()
        .max(200, 'Maximum 200 characters')
        .nullable()
        .required("Please enter your business's address"),
    addressPostCode: Yup.string()
        .max(20, 'Maximum 20 characters')
        .nullable()
        .required("Please enter your business's postal code"),
    addressCity: Yup.string()
        .max(200, 'Maximum 200 characters')
        .nullable()
        .required("Please enter your business's city"),
    addressState: Yup.string()
        .max(100, 'Maximum 100 characters')
        .nullable()
        .required("Please enter your business's state"),
    addressCountry: Yup.string()
        .max(10, 'Maximum 10 characters')
        .nullable()
        .required('Please select an option'),
    operatingAddressStreet: Yup.string().max(200, 'Maximum 200 characters').nullable(),
    operatingAddressNumber: Yup.string().max(200, 'Maximum 200 characters').nullable(),
    operatingAddressPostCode: Yup.string().max(20, 'Maximum 20 characters').nullable(),
    operatingAddressCity: Yup.string().max(200, 'Maximum 200 characters').nullable(),
    operatingAddressState: Yup.string().max(100, 'Maximum 100 characters').nullable(),
    operatingAddressCountry: Yup.string().max(10, 'Maximum 10 characters').nullable(),
});
const ownerInformationSchema = Yup.object({
    ownerInformation: Yup.object({
        exemptionId: Yup.number().nullable(),
        individualBeneficialOwners: Yup.array(individualBeneficialOwnersSchema),
        businessBeneficialOwners: Yup.array(businessBeneficialOwnerSchema),
        beneficialOwnersStructure: Yup.array()
            .of(beneficialOwnersStructureSchema)
            .when('exemptionId', {
                is: (val: number | null) => !val,
                then: (schema) => schema.min(1, 'Must have at least one beneficial owner'),
            })
            // .when('exemptionId', {
            //     is: (val: number | null) => !val,
            //     then: (schema) =>
            //         schema.test(
            //             'oneControllingParty',
            //             'Must have at least one controlling party',
            //             function (this, value: OwnerTreeStructure[] | undefined) {
            //                 if (!value || value?.some((o) => o.isNewNode)) return true;

            //                 return value
            //                     .filter((o) => !o.bIsBusiness)
            //                     .some((o) => o.bControllingParty);
            //             }
            //         ),
            // })
            ,
        bConfirmAboveTen: Yup.boolean()
            .nullable()
            .when('exemptionId', {
                is: (val: number | null) => !val,
                then: (schema) =>
                    schema
                        .required('Please confirm beneficial owners have 10% or more ownership')
                        .oneOf(
                            [true],
                            'Please confirm beneficial owners have 10% or more ownership'
                        ),
            }),

        authorizedSigner: Yup.string()
            .nullable()
            .required('Please name an authorized signer')
            .max(200, 'Maximum 200 characters'),
    }),
});
const termsSchema = Yup.object({
    terms: Yup.object({
        bTaxAcknowledgement: Yup.boolean()
            .nullable()
            .required('Please agree to the tax acknowledgement')
            .oneOf([true], 'Please agree to the tax acknowledgement'),
        bFatcaAcknowledgement: Yup.boolean()
            .nullable()
            .required('You must be compliance with FACTA')
            .oneOf([true], 'You must be compliance with FACTA'),
        bConfirmTrueAndCorrect: Yup.boolean()
            .nullable()
            .required('Please confirm the information you have provided is true and correct')
            .oneOf([true], 'Please confirm the information you have provided is true and correct'),
        bAcceptTerms: Yup.boolean()
            .nullable()
            .required('Please accept our terms of service')
            .oneOf([true], 'Please accept our terms of service'),
    }),
});
const documentsSchema = Yup.object({});

export const verificationValidationSchema: ValidationSchemas = {
    [BusinessVerificationStage.RegistrationInformation]: registrationInformationSchema,
    [BusinessVerificationStage.OperationsInformation]: operationsInformationSchema,
    [BusinessVerificationStage.OwnerInformation]: ownerInformationSchema,
    [BusinessVerificationStage.Terms]: termsSchema,
    [BusinessVerificationStage.Documents]: documentsSchema,
};

export const validateOwners = async (values: FormikValues) => {
    await ownerInformationSchema.validate(values, { abortEarly: false }).catch((err) => {
        return err;
    });
};

export function validate(
    values: FormikValues,
    submitValues: FormValues | undefined,
    apiErrors: processedErrors,
    validationSchema: Yup.SchemaOf<any>,
    rejectedDocuments: DocumentRejection | null | undefined
) {
    const handleRejected = (rejectedDocuments: DocumentRejection | null | undefined) => {
        let rejectedDocErrors: FormikErrors<any>[] = [];
        if (rejectedDocuments) {
            // TODO: Handle rejected documents
        }
        return rejectedDocErrors;
    };

    return new Promise(async function (resolve, reject) {
        const errorObject = await validationSchema
            .validate(values, { abortEarly: false })
            .then(() => {
                let rejectedDocErrors = handleRejected(rejectedDocuments);
                if (submitValues) {
                    let errors: FormikErrors<any> = {};
                    const diff = findDifferences(submitValues, values);
                    if (apiErrors.length > 0) {
                        apiErrors.forEach((error) => {
                            if (
                                error.fieldName.endsWith('Other') &&
                                diff.some((diffField) =>
                                    diffField.startsWith(error.fieldName.slice(0, -5))
                                )
                            )
                                return; // skip fieldNameOther fields if fieldName... has changed
                            if (diff && (!diff.includes(error.fieldName) || diff.length === 0)) {
                                errors = { ...errors, [error.fieldName]: error.message };
                            }
                        });
                    }
                    if (rejectedDocErrors.length > 0) {
                        rejectedDocErrors.forEach((error) => {
                            errors = { ...errors, ...error };
                        });
                    }
                    if (Object.keys(errors) && Object.keys(errors).length > 0) {
                        return transformDotNotationObject(errors);
                    }
                }
            })
            .catch((e) => {
                if (submitValues) {
                    let rejectedDocErrors = handleRejected(rejectedDocuments);
                    if (e instanceof Yup.ValidationError) {
                        let errors: FormikErrors<any> = e.inner.reduce(
                            (acc: FormikErrors<any>, currentError) => {
                                const errorPath = currentError.path as string;
                                if (errorPath && !acc[errorPath]) {
                                    acc[errorPath] = currentError.message;
                                }
                                return acc;
                            },
                            {}
                        );
                        const diff = findDifferences(submitValues, values);
                        if (apiErrors.length > 0) {
                            apiErrors.forEach((error) => {
                                if (
                                    diff &&
                                    (!diff.includes(error.fieldName) || diff.length === 0)
                                ) {
                                    errors = { ...errors, [error.fieldName]: error.message };
                                }
                            });
                        }
                        if (rejectedDocErrors.length > 0) {
                            rejectedDocErrors.forEach((error) => {
                                errors = { ...errors, ...error };
                            });
                        }
                        if (Object.keys(errors) && Object.keys(errors).length > 0) {
                            return transformDotNotationObject(errors);
                        }
                    }
                }
                if (e instanceof Yup.ValidationError)
                    return transformDotNotationObject(
                        e.inner.reduce((acc: FormikErrors<any>, currentError) => {
                            const errorPath = currentError.path as string;
                            if (errorPath && !acc[errorPath]) {
                                acc[errorPath] = currentError.message;
                            }
                            return acc;
                        }, {})
                    );
            });
        resolve(errorObject);
    });
}
